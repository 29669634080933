import React, { useEffect } from "react";

export const Linkedin = () => {
  useEffect(() => {
    if (!window) return;
    window._linkedin_data_partner_id = "175330";

    (function () {
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })();
  }, []);
  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: "none" }}
        alt=""
        src="https://dc.ads.linkedin.com/collect/?pid=175330&fmt=gif"
      />
    </noscript>
  );
};
