import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { backgroundGrey, darkBlue } from "site-ui/colors";
// import { LocaleLink as Link } from "gatsby-theme-twill-website";
import { Link } from "twill-net-localization";
export interface NavigationLinkProps {
  to: string;
  type?: "header" | "link" | "gatsby-link";
  label: string;
  icon?: ReactElement;
  forceEng?: boolean;
  "data-test-id": string;
}

const LinkRenderer: FC<
  NavigationLinkProps & React.HTMLAttributes<HTMLAnchorElement>
> = ({ to, label, className, type, forceEng = false, ...props }) => {
  switch (type) {
    case "header":
    case "link":
    case "gatsby-link":
      return (
        <Link
          to={to}
          className={className}
          language={forceEng ? "en" : null}
          {...props}
        >
          <span>{label}</span>
        </Link>
      );
    default:
      return (
        <a href={to} className={className} {...props}>
          <span>{label}</span>
        </a>
      );
  }
};

export const NavigationLink = styled(LinkRenderer)`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 42px;
  border-radius: 4px;
  color: ${darkBlue};
  will-change: background-color;
  transition: 0.1s background-color;
  &:after {
    content: "→";
    font-size: 24px;
    line-height: 24px;
    margin-left: 20px;
    transform: translateX(10px);
    will-change: transform;
    opacity: 0;
    transition: 0.1s transform;
  }
  &:hover {
    background-color: ${backgroundGrey};
    color: ${darkBlue};
    cursor: pointer;
    &:after {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
