import React from "react";
import { MenuItem } from "./components/MenuItem";
import styled from "styled-components";
import { menuBreakpoint } from "../../const/breakpoints";
import { ThemeProvider } from "styled-components";

export const Menu = ({ contentfulchildren, small, ...props }) => {
  return (
    <ThemeProvider theme={{ size: small ? "small" : "default" }}>
      <MenuWrapper small={small} {...props}>
        {contentfulchildren.map((item) => {
          return <MenuItem {...item} />;
        })}
      </MenuWrapper>
    </ThemeProvider>
  );
};

const MenuWrapper = styled.nav`
  ${(props) => (props.small ? "font-size: 14px;" : "font-size: 16px;")}
  @media (min-width: ${menuBreakpoint}) {
    display: flex;
    ${(props) => (props.small ? "height:40px;" : "height:88px;")}
  }
`;
