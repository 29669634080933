import React, { useContext } from "react";
import { Link } from "gatsby";
import DataContext from "../../providers/PageContextProvider";
import { localizeLink } from "../../../../tools/ui/helpers/localizeLink";

function LocaleLink({ to, forceEng = false, ...props }) {
  const {
    pageContext: { locale },
  } = useContext(DataContext);
  if (!to) {
    return null;
  }

  if (
    forceEng ||
    !locale ||
    to.charAt(0) !== "/" ||
    locale.toLowerCase() === "en-us"
  ) {
    return <Link to={to} {...props} />;
  }

  const path = localizeLink(locale, to);

  return <Link {...props} to={path} />;
}

export default LocaleLink;
