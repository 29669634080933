import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { LogoDarker } from "./LogoDarker";
import { LogoDark } from "./LogoDark";
import { LogoLight } from "./LogoLight";

export default ({ fill, title = "Twill Logo", ...props }) => {
  const theme = useContext(ThemeContext);

  switch (theme.mode) {
    case "darker":
      return <LogoDarker fill={fill} title={title} {...props} />;
    case "dark":
      return <LogoDark {...props} title={title} />;
    default:
      return <LogoLight fill={fill} title={title} {...props} />;
  }
};
