import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";
import { midBlue, lightBlue } from "site-ui/colors";

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-padding-top: 120px;
  }
  body {
    line-height: 1.5;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    padding: 0;
    margin: 0;
    min-width: 320px;
    background-color: ${({ theme }) => theme.bodyBackgroundColor};
  }
  p {
    margin: 0.5em 0;
  }
  a {
    color: ${lightBlue};
    text-decoration: none;
    &:hover {
      color: ${midBlue}
    }
  }
  p {
    a {
      border-bottom: 1px solid ${lightBlue};
      word-break: break-word;
      u {
        text-decoration: none;
      }
      &:hover {
        border-color: ${midBlue}
      }
    }
  }
  h1, h2, h3 {
    margin-bottom: 0.4em;
    color: ${({ theme }) => theme.headingColor};
  }
  h2 {
    font-weight: bold;
    font-size: 28px;
  }
  ul {
    list-style-type: disc;
    padding-left: 32px;
    margin-bottom: 1.5em;
    p {
      margin-bottom: 0!important;
    }
  }
  ol {
    list-style-type: decimal;
    padding-left: 32px;
    margin-bottom: 1.5em;
    p {
      margin-bottom: 0!important;
    }
  }
  strong, b {
    font-weight: bold;
  }
  i{
    font-style: italic;
  }
  [variant="lightColor"] p a + a {
    margin-left: 10px;
  }
  img {
    display: block;
  }
`;
