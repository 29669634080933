import styled from "styled-components";

export const Menu = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  max-width: 1232px;
  padding-left: 0;
  width: 100%;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;

    margin: 0 auto;
  }
`;
