import React from "react";
import { ReactElement } from "react";

export function LogoDark(props: { title: string }): ReactElement {
  const { title } = props;

  return (
    <svg
      role={"img"}
      viewBox="158.3099822998047 158.30999755859375 566.9299926757812 331.3699951171875"
      width="74"
      height="40"
      {...props}
    >
      {title && <title>{title}</title>}
      <path
        fill="white"
        d="M725.24 475.77l-19.56-21.71 18.48-18.32h-5.24L701 453.9v-32h-4.16v53.89H701v-21.4l18.93 21.4zm-49.27.77c9.55 0 14.24-2.85 14.24-11.55 0-8.47-4.77-10-8.93-11.09l-7.39-1.9c-3.54-.92-5.23-2.08-5.23-6.7 0-5.31 2.39-6.31 8.93-6.31a53.8 53.8 0 0111.24 1.16v-3.95a55.25 55.25 0 00-11.24-1.2c-8.78 0-13.16 2.69-13.16 10.31 0 6.62 2.23 9 8.16 10.55l8.08 2.08c3.39.84 5.31 2.08 5.31 7.62s-2.31 7-9.78 7a46.74 46.74 0 01-11.85-1.84v3.92a38 38 0 0011.65 1.9m-29.83-39l-.53-1.85h-3.47v40h4.16v-35.87h13.93V435zM631 453.21h-22.68c.08-13.08 4.39-14.24 12.47-14.24 6.85 0 10.16 2.39 10.16 11zm4.16-1.69c0-14-5.85-16.55-14.94-16.55-9.77 0-16 2.85-16 19.63 0 13.78 2.16 21.94 16.78 21.94a40.3 40.3 0 0012.47-1.85v-3.93a41.38 41.38 0 01-12.7 1.77c-10.39 0-12.08-5.08-12.39-15.7h26.79zm-63 14.39c0-5.15.85-7.23 6.85-7.69l13.71-1v13.71a69.88 69.88 0 01-13.25 1.84c-5.69 0-7.31-1.3-7.31-6.85M583 435a77.78 77.78 0 00-12.78 1.15v3.93a77.9 77.9 0 0112.66-1.08c8.85 0 9.78 2.39 9.78 6.62v8.08l-13.71 1c-7.69.62-11.16 3.39-11.16 11.24 0 7.55 2.7 10.63 11.32 10.63 4.23 0 8.46-1.31 13.7-3.24l.85 2.47h3.15V446c0-8.24-3.08-11-13.78-11m-72.6 40.8h4.08v-46.6l18.17 44.33c.38 1 .92 1.31 1.61 1.31h1.93c.69 0 1.23-.31 1.61-1.31L556 429.12v46.65h4.16v-53.89h-5.24l-19.63 48-19.47-48h-5.39zm-28.32-40l-12.55 34.64-13-34.64h-4.39l15.32 39.18-5.85 14.7h4.24l20.55-53.88zm-46.63 3.2c7.92 0 10.16 3.62 10.16 17.78 0 14.4-4 15.78-11.09 15.78a53.55 53.55 0 01-11.31-1.31v-30.89a56.73 56.73 0 0112.24-1.36m.92-4a34.59 34.59 0 00-13.24 3.15v-16.27H419v53.89h3.08l.77-2.31c3.39 1.31 8.08 3.08 12.09 3.08 9 0 15-2.85 15-19.79S446.33 435 436.4 435"
      />
      <path
        fill="white"
        d="M526.22 226.56v158.3h48.37v-158.3zm-341.3-34.3v34.3h-26.61V265h26.61v65c0 40 16 57.81 52 57.81 21.19 0 40.85-6.66 40.85-6.66v-40.5s-16.42 5.8-30.62 5.8c-7.57 0-13.83-1.35-13.83-17.92V265h61l31.12 119.89h49.46l22.26-84.62 22 84.62h49.46L510 226.56h-44.14l-23.38 90.66-23.84-90.66h-43l-23.73 90.87-23.75-90.87h-94.87v-57.49zm339.3 1.19a26.9 26.9 0 1032.34-32.34 28 28 0 00-6.15-.69 26.93 26.93 0 00-26.19 33m77.32-12v203.44h48.37V158.31zm75.32 0v203.44h48.38V158.31z"
      />
      <path
        fill="white"
        d="M526.22 384.86h48.37v-23.18zm75.32-36.09v36.1h48.37V325.6zm75.32-36.09v72.19h48.38V289.5z"
      />
    </svg>
  );
}
