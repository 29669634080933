import React, { useEffect } from "react";

export const SmartlingContext = () => {
  useEffect(() => {
    (function (w, o) {
      try {
        var h = document.getElementsByTagName("head")[0];
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = 1;
        s.crossOrigin = "anonymous";
        s.src = "//d2c7xlmseob604.cloudfront.net/tracker.min.js";
        s.onload = function () {
          w.SmartlingContextTracker.init({ orgId: o });
        };
        h.insertBefore(s, h.firstChild);
      } catch (ex) {}
    })(window, "-5PXIVN6hyrDQ+OpQ3qLPg");
  }, []);
  return null;
};
