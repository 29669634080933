import React from "react";
import { Section } from "site-ui/src/components/Section";
import { Menu } from "../Menu";
import { darkBlue, white, backgroundGrey } from "site-ui/colors";
import styled from "styled-components";
import Logo from "site-ui/src/components/Logo";
import { FooterTeaserContainer } from "../FooterTeaserContainer";
import { ThemeProvider } from "styled-components";

import { Trans } from "twill-net-localization";

export const Footer = ({ disableFooterNavigation }) => {
  return (
    <Wrapper>
      <FooterTeaserContainer />
      <ThemeProvider theme={{ mode: "dark" }}>
        <StyledMenuContainerLogo>
          <a href="/">
            <Logo />
          </a>
        </StyledMenuContainerLogo>
      </ThemeProvider>
      <Section padding={2}>
        {!disableFooterNavigation && (
          <MenuContainer>
            <Menu
              name="Footer"
              isVertical={true}
              extra={
                <Address>
                  <h4>
                    <Trans i18nKey="common.contact">Contact</Trans>
                  </h4>
                  <p>
                    <Trans i18nKey="common.address" values={{}}>
                      Twill Headquarters
                      <br />
                      De Kroon
                      <br />
                      Turfmarkt 107
                      <br />
                      2511 DP Den Haag
                    </Trans>
                  </p>
                  <h4>
                    <Trans i18nKey="common.social" values={{}}>
                      Social
                    </Trans>
                  </h4>
                  <Social>
                    <a
                      href="https://www.facebook.com/twilllogistics/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        class="social-icon"
                        alt="FB"
                        width="16"
                        height="16"
                        src="https://www.twill.net/wp-content/themes/twill/assets/images/facebook-white.png"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/twill-logistics/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        class="social-icon"
                        width="16"
                        height="16"
                        alt="Twitter"
                        src="https://www.twill.net/wp-content/themes/twill/assets/images/linkedin-white.png"
                      />
                    </a>
                  </Social>
                </Address>
              }
            />
          </MenuContainer>
        )}
      </Section>
    </Wrapper>
  );
};

const Social = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 16px;
  }
  a {
    width: 18px;
    height: 18px;
  }
  img {
    width: 100%;
  }
`;

const MenuContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  a {
    color: ${backgroundGrey};
  }
  a:hover {
    color: ${white};
  }
`;

const StyledMenuContainerLogo = styled(MenuContainer)`
  padding: 2.5rem 1.5rem;
`;

const Address = styled.div`
  h4 {
    color: ${white};
    margin-bottom: 1em;
    font-weight: bold;
  }
  p {
    font-weight: 200;
  }
`;

const Wrapper = styled.footer`
  background-color: ${darkBlue};
  font-size: 14px;
  line-height: 1.8em;
  h3 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: ${white};
    @media (min-width: 800px) {
      font-size: 40px;
    }
  }
`;
