import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import { Menu } from "./components/Menu";
import { MenuItemContainer } from "./components/MenuItemContainer";
import { ActionTypes, useNavigationState } from "./NavigationContext";
import { backgroundGrey } from "site-ui/colors";
import Logo from "../Logo";
import { Signup } from "./components/Signup";
import { Burger } from "./components/Burger";
import { useTopOfPage } from "../../hooks/useScroll";
import useWindowSize from "site-ui/src/hooks/useWindowSize";

export const NavigationBar: FC<{
  logoLink: string;
  languageSwitcher: ReactNode;
  disableNavigation?: boolean;
}> = ({ logoLink = "/", languageSwitcher, disableNavigation }) => {
  const {
    menu,
    state: { isOpen, isMobileMenuOpen },
    dispatch,
    menuBarOverlay,
    mode,
  } = useNavigationState();
  const ref = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const isTop = useTopOfPage(ref);
  const [calculatedMode, setMode] = useState(mode);

  useEffect(() => {
    setMode(isTop && !isOpen && window.innerWidth > 960 ? mode : "light");
  }, [isTop, mode, isOpen, isMobileMenuOpen, windowSize.width > 960]);

  return (
    <ThemeProvider
      theme={{
        mode: calculatedMode,
      }}
    >
      <Overlay
        onClick={() => dispatch({ type: ActionTypes.CLOSE })}
        isOpen={isOpen}
        ref={ref}
      >
        <Wrapper
          isOpen={isOpen}
          isMobileMenuOpen={isMobileMenuOpen}
          isScrolled={!isTop}
          menuBarOverlay={menuBarOverlay}
          onClick={(e) => e.stopPropagation()}
        >
          <SideWrapper>
            <a href={logoLink} data-test-id="header-logo">
              <Logo />
            </a>
            <Burger
              onClick={() => dispatch({ type: ActionTypes.TOGGLE_MOBILE_MENU })}
              open={isMobileMenuOpen}
            >
              <span />
              <span />
              <span />
            </Burger>
          </SideWrapper>
          <Menu>
            {!disableNavigation &&
              menu.map((item) => (
                <MenuItemContainer
                  item={item}
                  data-test-id="menu-item-container"
                />
              ))}
          </Menu>
          <SideWrapper align="flex-end">
            <Signup>{languageSwitcher}</Signup>
          </SideWrapper>
        </Wrapper>
      </Overlay>
    </ThemeProvider>
  );
};

const SideWrapper = styled.div<{
  align?: "flex-start" | "center" | "flex-end";
}>`
  width: 100%;
  flex-shrink: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ align }) => `justify-content: ${align}`};
  white-space: nowrap;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 33%;
  }
`;

const solidStyles = css`
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid ${backgroundGrey};
`;
const transparentStyles = css`
  *,
  a,
  span {
    ${({ theme }) => (theme.mode === "dark" ? "color: #fff; " : "")}
    &:hover {
      ${({ theme }) => (theme.mode === "dark" ? "color: #fff; " : "")}
    }
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    background-color: rgba(255, 255, 255, 0);
  }
`;

const Wrapper = styled.div<{
  isOpen: boolean;
  isMobileMenuOpen: boolean;
  menuBarOverlay: boolean;
  isScrolled?: string;
}>`
  will-change: background-color;
  transition: 0.1s background-color;
  background-color: #fff;
  width: 100%;
  z-index: 2000;
  box-sizing: border-box;
  padding: 12px 24px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  ${(props) =>
    !props.menuBarOverlay || props.isScrolled || props.isOpen
      ? solidStyles
      : transparentStyles};
  ${({ isMobileMenuOpen }) =>
    isMobileMenuOpen
      ? css`
          height: 100%;
          position: fixed;
          top: 0;
        `
      : css`
          height: 72px;
          overflow: hidden;
          ${Menu}, ${SideWrapper}:last-child {
            display: none;
          }
        `}
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    overflow-y: visible;
    padding: 0 32px;
    height: 104px;
    align-items: center;
    align-content: center;
    overflow: visible;
    ${Menu}, ${SideWrapper}:last-child {
      display: flex;
    }
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  z-index: 10;
  /* position: sticky; */
  right: 0;
  left: 0;
  will-change: background-color;
  transition: 0.2s background-color;
  transition-delay: 0.1s;
  background-color: rgba(0, 0, 0, 0);
  ${({ isOpen }) =>
    !!isOpen &&
    css`
      overflow-y: auto;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      display: block;
      position: fixed;
    `};
`;
