import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import { getCanonicalUrl } from "./getCanonicalUrl";

const SEO = ({
  title,
  description,
  previewImage,
  image,
  article,
  lang,
  noIndex,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const seoImage =
    previewImage && previewImage.file ? previewImage.file.url : image;

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata;

  // Use a specific Title only on the Chinese landing page
  const onChineseUrlAndHomePage = pathname === "/zh-Hans/";
  const inPreview = titleTemplate.includes("<PREVIEW>");
  const useTemplate = inPreview || !onChineseUrlAndHomePage;

  const canonicalUrl = getCanonicalUrl(siteUrl, pathname);

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: seoImage && `${seoImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={useTemplate && titleTemplate}
      link={[
        {
          rel: "canonical",
          href: canonicalUrl,
        },
      ]}
    >
      <html lang={lang} />
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.image && <meta name="image" content={seo.image} />}

      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        # defaultDescription: description
        siteUrl
        # defaultImage: image
        # twitterUsername
      }
    }
  }
`;
