import React, { useState, useRef } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import { darkBlue, white, midBlue, grey } from "../../../../colors";
import { Dropdown } from "./Dropdown";
import { Chevron } from "../../../icons/Chevron";
import { menuBreakpoint } from "../../../const/breakpoints";
import { tint } from "polished";
import { backgroundGrey } from "site-ui/colors";
import theme from "styled-theming";
import { useOnClickOutside } from "../../../hooks/useOutsideAlerter";

const DefaultRenderer = ({ url, children, className, ...props }) => (
  <a href={url} className={className} {...props}>
    {children}
  </a>
);

const color = theme("mode", {
  default: darkBlue,
  light: darkBlue,
  dark: white,
});

const sizeStyles = theme("size", {
  default: css`
    padding: 14px 0;
    font-weight: bold;
    li {
      font-weight: normal;
    }
    @media (min-width: ${menuBreakpoint}) {
      padding: 0px 24px;
    }
  `,
  small: css`
    padding: 14px 0;
    font-weight: normal;
    @media (min-width: ${menuBreakpoint}) {
      padding: 0px 12px;
    }
  `,
});

export const MenuItem = (props) => {
  const [isOpen, setOpen] = useState(props.isOpen);
  const [isOver, setOver] = useState(props.isOpen);
  const { active, childMenuItem, Renderer = DefaultRenderer } = props;
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setOpen(false);
    setOver(false);
  });

  return (
    <MenuItemStyled
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      ref={ref}
      isOpen={isOpen}
      isOver={isOver}
      active={active}
    >
      <Renderer
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          if (childMenuItem && window.innerWidth < 900) {
            e.preventDefault();
          }
          setOpen(!isOpen);
        }}
      >
        <Title>
          {props.title}
          {/* {childMenuItem && (
            <ChevronStyled top={isOpen} width={20} height={20} />
          )} */}
        </Title>
      </Renderer>
      {childMenuItem && (
        <ThemeProvider theme={{ mode: "light" }}>
          <Dropdown childMenuItem={childMenuItem} />
        </ThemeProvider>
      )}
    </MenuItemStyled>
  );
};

const ChevronStyled = styled(Chevron)`
  margin-left: 4px;
`;

const Title = styled.div`
  border-bottom: none;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: stretch;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  color: ${darkBlue};
  @media (min-width: ${menuBreakpoint}) {
    color: ${color || darkBlue};
    &:hover {
      color: ${color};
    }
  }
  ${sizeStyles}
`;

const MenuItemStyled = styled.li`
  list-style-type: none;
  margin-bottom: 0;
  position: relative;
  a,
  span {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-content: stretch;
    justify-items: stretch;
    align-items: stretch;
  }
  ${Dropdown} {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }

  @media (min-width: ${menuBreakpoint}) {
    display: flex;
    justify-content: flex-start;
    align-content: stretch;
    position: relative;
    border-bottom: none;

    ${Dropdown} {
      display: ${(props) => (props.isOpen ? "grid" : "none")};
      padding: 0;
      grid-auto-flow: row;
      ${Title} {
        width: 100%;
        color: ${darkBlue};
        ${(props) =>
          props.small ? `padding: 4px 24px` : `padding: 16px 32px`};
        &:hover {
          background-color: ${tint(0.3, backgroundGrey)};
          color: ${midBlue};
        }
      }
    }
  }
`;
