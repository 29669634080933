function isLocalizedLink(locale: string, link: string): boolean {
  return link.indexOf(`/${locale}/`) === 0 || link.indexOf(`${locale}/`) === 0;
}

export function localizeLink(locale: string, link: string): string {
  if (isExternalLink(link) || isHashLink(link)) {
    return link;
  }

  if (
    locale.toLowerCase() === "en-us" ||
    isLocalizedLink(locale, link) ||
    isLinkToPlatform(link)
  ) {
    return prefixWithSlash(link);
  }

  if (link === "/") {
    return `/${locale}`;
  }

  return `/${locale}/${cutPrefixSlash(link)}`;
}

function isExternalLink(link: string): boolean {
  return link.indexOf("https://") === 0 || link.indexOf("http://") === 0;
}

function isLinkToPlatform(link: string): boolean {
  return link.indexOf("/my/") === 0 || link.indexOf("my/") === 0;
}

function isHashLink(link: string): boolean {
  return link.indexOf("#") === 0;
}

function cutPrefixSlash(link: string): string {
  if (link.indexOf("/") === 0) {
    link = link.substr(1);
  }

  return link;
}

function prefixWithSlash(link: string): string {
  if (link.indexOf("/") === 0) {
    return link;
  }

  return `/${link}`;
}
