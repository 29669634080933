import React from "react";
import { darkBlue } from "site-ui/colors";

export const Cross = ({ background, fill = darkBlue }) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <title>Group 4</title>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="lg-1440-Service---Value-protect" fill={fill} fill-rule="nonzero">
          <g>
            {background && (
              <rect fill={background} width={24} height={24} rx={30} />
            )}
            <g id="Group-4" transform="translate(7, 7)">
              <path
                d="M7.93933983,0.456631536 C8.52512626,-0.152210512 9.47487373,-0.152210512 10.0606602,0.456631536 C10.6013861,1.01863958 10.6429804,1.90297411 10.1854431,2.51457085 L10.0606602,2.66144363 L3,10 C2,10.6666667 1.16666667,10.6666667 0.5,10 C-0.166666667,9.33333333 -0.166666667,8.5 0.5,7.5 L7.93933983,0.456631536 Z"
                id="Path-14"
              ></path>
              <path
                d="M7.93933983,0.456631536 C8.52512626,-0.152210512 9.47487373,-0.152210512 10.0606602,0.456631536 C10.6013861,1.01863958 10.6429804,1.90297411 10.1854431,2.51457085 L10.0606602,2.66144363 L3,10 C2,10.6666667 1.16666667,10.6666667 0.5,10 C-0.166666667,9.33333333 -0.166666667,8.5 0.5,7.5 L7.93933983,0.456631536 Z"
                id="Path-15"
                transform="translate(5.250000, 5.250000) rotate(90.000000) translate(-5.250000, -5.250000) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
