import React, { useEffect } from "react";
import styled from "styled-components";

const demoChatUrl = `https://demo.maersk.com/support/chat/#/src=twl`;
const prodChatUrl = `https://www.maersk.com/support/chat/#/src=twl`;
function getLiveChatUrl(hostname) {
  const isProduction =
    hostname === "www.twill.net" ||
    hostname === "twill.net" ||
    hostname === "www.twill.net.cn" ||
    hostname === "twill.net.cn";

  return isProduction ? prodChatUrl : demoChatUrl;
}

export function openLiveChat() {
  const chatUrl = getLiveChatUrl(window.location.hostname);

  window.open(
    chatUrl,
    "targetWindow",
    "toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=400,height=640"
  );
}

export const LiveChatButton = () => {
  useEffect(() => {
    window.sf_brand = "Maersk Line";
    window.openLiveChat = openLiveChat;
  }, []);
  return <LiveChatButtonStyled onClick={openLiveChat}></LiveChatButtonStyled>;
};

const LiveChatButtonStyled = styled.button`
  border: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' class='lc-r5bo2g e5ibypu0'%3E%3Cpath d='M14 25.5c-1.6 0-3.2-.3-4.6-.8l-4.9 2.8v-5.6C2 19.6.5 16.5.5 13 .5 6.1 6.5.5 14 .5S27.5 6.1 27.5 13s-6 12.5-13.5 12.5zm-5-14c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zm5 0c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zm5 0c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5z' fill='rgb(67, 132, 245)' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  width: 60px;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  border-radius: 50%;
  cursor: pointer;
`;
