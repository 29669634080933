import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { darkBlue } from "site-ui/colors";

let theme = createTheme({
  palette: {
    primary: { main: darkBlue },
  },
  textField: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  input: {
    color: "white",
  },

  typography: {
    h1: {
      fontSize: 48,
      fontWeight: "bold",
      marginBottom: "1em",
    },
    h2: {
      fontSize: 40,
      fontWeight: "bold",
    },
    h3: {
      fontSize: 26,
      marginTop: 10,
      fontWeight: "bold",
    },
    h4: {
      fontSize: 20,
      lineHeight: 1.5,
      fontWeight: "normal",
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: "normal",
      textTransform: "uppercase",
    },
    h6: {
      fontSize: 20,
      lineHeight: 1.5,
      fontWeight: "normal",
    },
    body1: {
      fontWeight: 100,
      marginBottom: 10,
      fontSize: 16,
      lineHeight: 1.8,
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: 1.5,
    },
    fontSize: 16,
    fontFamily: [
      '"Maersk Text"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

theme = responsiveFontSizes(theme);

export { theme };
