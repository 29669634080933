import React, { useContext } from "react";
import { DataContext } from "../../providers/PageContextProvider";
import { Navigation, NavigationBar } from "site-ui/src/components/Navigation";
import LanguageSwitcher from "site-ui/src/components/LanguageSwitcher/index";
import { graphql, useStaticQuery } from "gatsby";
import { NotificationContainer } from "../NotificationContainer";

export const Header = (props) => {
  const {
    pageContext: { locale, alternates },
  } = useContext(DataContext);
  const {
    allContentfulMenu: { nodes },
  } = useStaticQuery(query);
  const menu = nodes.find((node) => node.node_locale === locale);
  return [
    <NotificationContainer />,
    <Navigation
      menu={menu ? menu.contentfulchildren : []}
      menuBarOverlay={props.variant == "transparent"}
      mode={props.mode}
    >
      <NavigationBar
        languageSwitcher={
          alternates &&
          alternates.lang && (
            <LanguageSwitcher
              alternates={alternates.lang}
              currentLocale={locale}
            />
          )
        }
        disableNavigation={props.shortMenu}
        logoLink={locale !== "en-US" ? `/${locale}` : "/"}
      />
    </Navigation>,
  ];
};

const query = graphql`
  query Navigation {
    allContentfulMenu(filter: { name: { eq: "Header" } }) {
      nodes {
        __typename
        id
        node_locale
        name
        contentfulchildren {
          __typename
          title
          ...MenuItem
        }
      }
    }
  }
`;
