import React, { FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import {
  backgroundGrey,
  darkBlue,
  infoBg,
  midBlue,
  warningBg,
} from "../../../colors";
import { Close } from "../../icons/Close";

export enum AlertTypes {
  INFO = "INFO",
  WARNING = "WARNING",
  FAREWELL = "FAREWELL",
}

export interface NotificationProps {
  actions?: ReactNode;
  icon?: ReactNode;
  variant?: AlertTypes;
}

const NotificationButton = styled.button`
  background-color: transparent;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
`;

export const NotificationCloseButton: FC = (props) => (
  <NotificationButton {...props}>
    <Close fill={darkBlue} width={24} height={24} />
  </NotificationButton>
);

export const Notification: FC<NotificationProps> = ({
  actions,
  icon,
  variant = AlertTypes.INFO,
  children,
}) => {
  return (
    <Wrapper variant={variant}>
      <NotificationContent>
        {icon}
        {children}
      </NotificationContent>
      <Actions>{actions}</Actions>
    </Wrapper>
  );
};

const getTypeStyles = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.FAREWELL:
      return css`
        background-color: ${darkBlue};
        color: white;
        font-weight: 600;
        p > a {
          color: white;
          border-color: white;
        }
      `;
    case AlertTypes.INFO:
      return css`
        background-color: ${infoBg};
      `;
    case AlertTypes.WARNING:
      return css`
        background-color: ${warningBg};
      `;
    default:
      return css`
        background-color: ${backgroundGrey};
      `;
  }
};

const Wrapper = styled.div<{ variant: AlertTypes }>`
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: cetner;
  position: relative;
  ${({ variant }) => getTypeStyles(variant)}
  a {
    color: ${midBlue};
    border-color: ${midBlue};
  }
`;

const NotificationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -12px;
`;
