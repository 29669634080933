import React from "react";
import styled from "styled-components";

export const GradientTopBase = ({
  start = "#ABEBE000",
  end = "#ABEBE088",
  ...props
}) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="698"
      height="384"
      viewBox="0 0 698 384"
      {...props}
    >
      <defs>
        <linearGradient
          id="3nvy4dcoza"
          x1="1.142%"
          x2="95.212%"
          y1="57.549%"
          y2="41.85%"
        >
          <stop offset="0%" stopColor={start} opacity="0.8" />
          <stop offset="100%" stopColor={end} opacity="0.7" />
        </linearGradient>
      </defs>
      <g fill="url(#3nvy4dcoza)">
        <polygon points="0, 0 0,384 698,0" />
      </g>
    </svg>
  );
};

export const GradientBottomBase = ({
  start = "#ABEBE055",
  end = "#ABEBE000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="486"
      height="262"
      viewBox="0 0 486 262"
      {...props}
    >
      <defs>
        <linearGradient
          id="4cxdqgh3qa"
          x1="0%"
          x2="100%"
          y1="57.549%"
          y2="42.275%"
        >
          <stop offset="0%" stopColor={start} opacity="0.7" />
          <stop offset="100%" stopColor={end} opacity="0.7" />
        </linearGradient>
      </defs>
      <g fill="url(#3nvy4dcoza)">
        <polygon points="486,262  486,0 0,262" style={{ opacity: 0.6 }} />
      </g>
    </svg>
  );
};

export const GradientTop = styled(GradientTopBase)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  max-width: 45%;
  height: auto;
`;

export const GradientBottom = styled(GradientBottomBase)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  height: auto;
  max-width: 45%;
`;
