import React from "react";
import styled from "styled-components";
import { Check } from "../../icons/Check";
import { Cross } from "../../icons/Cross";
import Box from "@material-ui/core/Box";
import { limeGreen } from "site-ui/colors";

export const ChecklistCheck = ({
  children,
  isCross,
  background,
  as = "li",
  fill = limeGreen,
}) => {
  return (
    <Wrapper as={as}>
      {!isCross ? (
        <Check background={background} fill={fill} width={24} height={24} />
      ) : (
        <Cross background={background} fill={fill} width={24} height={24} />
      )}
      <Box ml={background ? 3 : 1}>{children}</Box>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  display: flex;
  text-align: left;
  h4 {
    font-size: 20px;
    margin-top: -3px;
    font-weight: bold;
  }
  & > :first-child {
    flex-shrink: 0;
  }
`;
