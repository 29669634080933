import React, { useContext, useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { DataContext } from "../../providers/PageContextProvider";
import { Menu as MenuComponent } from "site-ui/src/components/Menu";
import { FooterMenu } from "site-ui/src/components/FooterMenu";
import { Link } from "twill-net-localization";

const isMenuItemEmpty = (item) =>
  !!(
    (item.childMenuItem && item.childMenuItem.length !== 0) ||
    item.page ||
    item.url
  );

const defineRenderer = (items, locale) => {
  return items
    .filter(isMenuItemEmpty)
    .map((item) => {
      if (item.childMenuItem) {
        item.childMenuItem = defineRenderer(item.childMenuItem);
      }
      if (item.page && item.page.seoMeta && item.page.seoMeta.slug) {
        item.Renderer = ({ children }) => (
          <Link to={item.page.seoMeta.slug}>{children}</Link>
        );
      }
      return item;
    })
    .filter(isMenuItemEmpty);
};

export const Menu = ({ name, isVertical, ...props }) => {
  const {
    pageContext: { locale },
  } = useContext(DataContext);

  const {
    allContentfulMenu: { nodes: menus },
  } = useStaticQuery(graphql`
    query AllMenuQuery {
      allContentfulMenu {
        nodes {
          name
          node_locale
          contentfulchildren {
            ...MenuItem
          }
        }
      }
    }
  `);
  const menu = useMemo(() => {
    return menus.find(
      (menu) => menu.node_locale === locale && menu.name === name
    );
  }, [locale, name, menus]);

  if (!menu || menu.length === 0) {
    return null;
    // throw new Error("Menu is not defined");
  }

  menu.contentfulchildren = defineRenderer(menu.contentfulchildren, locale);

  return isVertical ? (
    <FooterMenu {...menu} {...props} />
  ) : (
    <MenuComponent {...menu} {...props} />
  );
};
