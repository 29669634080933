import React from "react";
import Typography from "@material-ui/core/Typography";
import { GradientBottom, GradientTop } from "../Gradient";
import styled from "styled-components";
import { darkBlue, lightBlue, textColor } from "site-ui/colors";
import { tint, transparentize } from "polished";
import { ChecklistCheck } from "site-ui/src/components/ChecklistCheck";

const HorizontalList = styled.ul`
  margin: 0;
  padding: 0;
  justify-content: center;
  * > li {
    margin-bottom: 4px;
  }
  @media (min-width: 640px) {
    display: flex;
    & > * {
      margin-right: 20px;
      word-wrap: nowrap;
    }
  }
`;

export const FooterTeaser = ({
  heading,
  text,
  cta,
  image,
  bgColor = lightBlue,
  color = textColor,
  features,
}) => {
  return (
    <Wrapper bgColor={bgColor} color={color}>
      <GradientBottom
        style={{ zIndex: 1 }}
        start={tint(0.5, bgColor)}
        end={transparentize(1, tint(0.5, bgColor))}
      />
      <GradientTop
        start={transparentize(1, tint(0.5, bgColor))}
        end={tint(0.5, bgColor)}
      />
      <Content>
        <Typography variant="h2" gutterBottom>
          {heading}
        </Typography>
        <div>{text}</div>
        <div>{cta}</div>
        {features && (
          <HorizontalList>
            {features.map((feature) => (
              <ChecklistCheck>{feature.title}</ChecklistCheck>
            ))}
          </HorizontalList>
        )}
      </Content>
      <ImageContainer>{image}</ImageContainer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  min-height: 450px;
  h2,
  li,
  div,
  p {
    color: ${(props) => props.color};
  }
  padding: 0 20px;
  img {
    width: 100%;
    display: block;
  }
`;

const Content = styled.div`
  margin: 20px 0;
  max-width: 640px;
  text-align: center;
  position: relative;
  color: ${darkBlue};
  z-index: 2;
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
  @media (min-width: 960px) {
    margin: 4vw 0 3vw;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 373px;
  max-width: 960px;
  width: 100%;
  z-index: 0;
`;
