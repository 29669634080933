import React from "react";
import styled from "styled-components";
import { backgroundGrey } from "site-ui/colors";
import { LocaleLink as Link, useLocale } from "gatsby-theme-twill-website";
import { Trans, useTranslation } from "twill-net-localization";
import { Box } from "@material-ui/core";
import { Sections } from "../../../../tools/ui/constants";
import { getSectionRoute } from "../../../../tools/ui/helpers/getSectionRoute";
import { localizeLink } from "../../../../tools/ui/helpers/localizeLink";
import cnWebP from "../../../../../website/src/images/cn.webp";

const DefaultLinkElement = styled.a`
  font-weight: 200;
`;

function DefaultLink({ item }) {
  const locale = useLocale();

  let href = null;

  if (!(item.page && item.page.seoMeta)) {
    href = localizeLink(locale, item.url);
  } else if (!!item.page) {
    href = localizeLink(locale, item.page.seoMeta.slug);
  }

  return <DefaultLinkElement href={href}>{item.title}</DefaultLinkElement>;
}

const LinkItem = styled(Link)`
  font-weight: 200;
`;

const Item = ({ item }) => {
  const locale = useLocale();
  const { t } = useTranslation();
  switch (item.page && item.page.__typename) {
    case "ContentfulServicesPageAssembly":
      return (
        <div>
          <h4>{item.title}</h4>
          <ul>
            {item.page &&
              item.page.categories.map((category, key) => (
                <React.Fragment key={key}>
                  {category.service &&
                    category.service
                      .filter(
                        (service) =>
                          !!service.seoMeta && !service.excludeFromMenu
                      )
                      .map((service, key) => (
                        <li key={service.seoMeta.slug}>
                          <LinkItem
                            to={`/${getSectionRoute(
                              locale,
                              Sections.SERVICES
                            )}/${service.seoMeta.slug}`}
                          >
                            {service.title}
                          </LinkItem>
                        </li>
                      ))}
                </React.Fragment>
              ))}
          </ul>
        </div>
      );
    case "ContentfulRichTextBlockRoutes":
      return (
        <ul>
          <h4>{item.title}</h4>
          {item.page.routes.map((route) => (
            <li key={route.slug}>
              <LinkItem to={`${item.url}${route.slug}`}>
                <Trans
                  i18nKey="common.shippingTo"
                  values={{ route: route.name }}
                  defaults="Container shipping to {{ route }}"
                />
              </LinkItem>
            </li>
          ))}
          <Box component="li" mt={2}>
            <LinkItem to={item.url}>
              <Trans
                i18nKey="common.shippingCountries"
                defaults="More countries"
              />
            </LinkItem>
          </Box>
        </ul>
      );
    case "ContentfulKnowledgeHubPageAssembly":
      return (
        <ul>
          <h4>{item.title}</h4>
          {item.page.categories.map((category) => {
            if (!category.seoMeta) return;

            return (
              <li key={category.seoMeta.slug}>
                <LinkItem
                  to={`/${getSectionRoute(locale, Sections.KNOWLEDGE_HUB)}/${
                    category.seoMeta.slug
                  }`}
                >
                  {category.seoMeta.title}
                </LinkItem>
              </li>
            );
          })}
        </ul>
      );
    default:
      if (!!item.childMenuItem) {
        return (
          <ul>
            <h4>
              <a href={item.url}>{item.title}</a>
            </h4>
            {item.childMenuItem?.map((item) => (
              <li key={item.id}>
                <DefaultLink item={item} />
              </li>
            ))}
          </ul>
        );
      }
      return <DefaultLink item={item} />;
  }
};

export const FooterMenu = ({ contentfulchildren, extra, ...props }) => {
  const isSubFooter =
    props.name === "Sub Footer" ||
    contentfulchildren.some((children) => {
      return (
        children.url?.indexOf("cookie-statement") > -1 ||
        children.url?.indexOf("terms.maersk.com") > -1 ||
        children.url?.indexOf("privacy-policy") > -1
      );
    });
  const isChineseLocale = props.node_locale === "zh-Hans";
  let isChineseDomain = undefined;
  try {
    isChineseDomain = window.location.origin.indexOf("twill.net.cn") > -1;
  } catch (e) {
    isChineseDomain = false;
  }

  return (
    <Wrapper>
      {isSubFooter && (isChineseLocale || isChineseDomain) && (
        <>
          <DefaultLinkElement href="https://beian.miit.gov.cn/">
            沪ICP备13023535号-7
          </DefaultLinkElement>
          <CnLink href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102006641">
            <>
              <CnIcon src={cnWebP} />
              <CnText>沪公网安备 31010102006641号</CnText>
            </>
          </CnLink>
        </>
      )}
      {contentfulchildren.map((item) => {
        return <Item item={item} key={item.id} />;
      })}
      {extra}
    </Wrapper>
  );
};

const CnLink = styled(DefaultLinkElement)`
  position: relative;
`;

const CnIcon = styled.img`
  position: absolute;
`;

const CnText = styled.span`
  padding-left: 25px;
`;

const Wrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  color: ${backgroundGrey};

  justify-content: space-between;
  & > *:not(:last-child) {
    margin-right: 32px;
  }
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
  h4 {
    font-weight: bold;
    margin-bottom: 1em;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 3em;
  }
`;
