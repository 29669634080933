import React from "react";
import { darkBlue } from "site-ui/colors";

export const Check = ({ background, fill = darkBlue, ...props }) => {
  return (
    <svg role="img" width={24} height={24} viewBox="0 0 24 24" {...props}>
      <title>Check icon</title>
      <g fill="none" fill-rule="evenodd">
        {background && (
          <rect fill={background} width={24} height={24} rx={30} />
        )}
        <path
          fill={fill}
          fill-rule="nonzero"
          stroke={fill}
          transform={background && `scale(0.7) translate(5,5)`}
          d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z"
        />
      </g>
    </svg>
  );
};
