import { useState, useEffect, RefObject } from "react";
import throttle from "lodash/throttle";

export const useTopOfPage = (ref: RefObject<HTMLDivElement>) => {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", throttle(scrollHandler, 300));
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });
  const scrollHandler = () => {
    setIsTop(
      window &&
        (!ref.current ? 0 : ref.current?.getBoundingClientRect().top) >=
          window.scrollY
    );
  };
  return isTop;
};

export const useScroll = (throttleDelay = 1000) => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", throttle(scrollHandler, throttleDelay));
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });
  const scrollHandler = () => {
    setScroll(window.scrollY);
  };
  return scroll;
};

export default useScroll;
