import React, { useContext } from "react";
import Helmet from "react-helmet";
import { DataContext } from "../../providers/PageContextProvider";

export const CookieInformation = () => {
  const {
    pageContext: { locale },
  } = useContext(DataContext);
  return (
    <Helmet>
      <script
        id="CookieConsent"
        src="https://policy.app.cookieinformation.com/uc.js"
        dataCulture={locale}
        type="text/javascript"
      ></script>
    </Helmet>
  );
};
