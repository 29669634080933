import React from "react";
import styled from "styled-components";
import { darkBlue3, darkBlue, white } from "site-ui/colors";
import { Container } from "@material-ui/core";
import { Menu } from "../Menu";
import { mix } from "polished";

const themes = {
  dark: {
    backgroundColor: darkBlue,
    color: darkBlue3,
  },
  light: {
    color: darkBlue,
    backgroundColor: white,
  },
};

const MenuStyled = styled(Menu)`
  font-weight: normal;
  color: ${darkBlue};
  justify-self: end;
`;

export const SubFooter = ({ theme = "dark" }) => {
  const year = new Date().getFullYear();
  return (
    <Wrapper
      backgroundColor={themes[theme].backgroundColor}
      color={themes[theme].color}
    >
      <Container>
        <FooterWrapper>
          <Copy>
            &copy; {year} &nbsp;&nbsp;&nbsp;Turfmarkt 107, 2511 DP Den Haag, The
            Netherlands
          </Copy>
          <MenuStyled isVertical={true} name="Sub Footer"></MenuStyled>
        </FooterWrapper>
      </Container>
    </Wrapper>
  );
};

const Copy = styled.div`
  margin-right: 20px;
`;

const FooterWrapper = styled.div`
  font-size: 14px;
  padding: 8px 0;
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
  @media (min-width: 1024px) {
    display: flex;
    min-height: 52px;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    & > :not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-top: 1px solid
    ${(props) => mix(0.7, props.backgroundColor, props.color)};
`;
