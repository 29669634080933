import React, { FC } from "react";
import { MenuItem } from "./MenuItem";
import { ButtonLink } from "site-ui/src/components/Button";
import styled from "styled-components";
import { useTranslation } from "twill-net-localization";

export const Signup: FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Switcher>{children}</Switcher>
      <MenuItem>
        <a href="/my/login">{t("common.logIn", "Log in")}</a>
      </MenuItem>
      <MobileButtons>
        <ButtonLink variant="secondary" href="/my/login">
          {t("common.logIn", "Log in")}
        </ButtonLink>
      </MobileButtons>
      <ButtonLink href="/my/sign-up">
        {t("common.signUp", "Sign up")}
      </ButtonLink>
    </Wrapper>
  );
};

const MobileButtons = styled.div`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

const Switcher = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    border-right: 1px solid #979797;
    margin-right: 32px;
    padding-right: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    ${ButtonLink} {
      width: 100%;
      margin-top: 14px;
      text-align: center;
      box-sizing: border-box;
    }
    ${MenuItem} {
      display: none;
    }
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    margin-top: 0;
    justify-content: flex-end;
    ${MenuItem} {
      display: block;
      margin-right: 32px;
    }
  }
`;
