import React from "react";
export const DataContext = React.createContext();

const DataProvider = ({ children, value }) => {
  const { pageContext } = value;
  if (!pageContext.locale) {
    pageContext.locale = "en-US";
  }
  return (
    <DataContext.Provider
      value={{
        pageContext,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
export { DataProvider };
