import React, { FC } from "react";
import { NavigationLink } from "../NavigationLink";
import { MenuItem as MenuItemType } from "../types";
import { Sections } from "../../../../../tools/ui/constants";
import { getSectionRoute } from "../../../../../tools/ui/helpers/getSectionRoute";
import { useLocale } from "../../../../../gatsby-theme-twill-website";

export const NavigationGroupRenderer: FC<{ item: MenuItemType }> = ({
  item,
}) => {
  const locale = useLocale();
  switch (item.page?.__typename) {
    case "ContentfulFaqMainPageSettings":
      return (
        item.page.categories &&
        item.page.categories.map((category) => {
          if (category?.seoMeta?.slug) {
            return (
              <NavigationLink
                to={`/${item.page.page[0].seoMeta.slug}/${category.seoMeta.slug}`}
                label={category.name}
                type="gatsby-link"
                data-test-id="navigation-link"
              />
            );
          }
        })
      );
    case "ContentfulKnowledgeHubPageAssembly":
      return item.page.categories.map((category) => {
        if (!category.seoMeta) return;

        return (
          <NavigationLink
            to={`/${item.page?.page[0]?.seoMeta.slug}/${category.seoMeta.slug}`}
            label={category.seoMeta.title}
            type="gatsby-link"
            data-test-id="navigation-link"
          />
        );
      });
    case "ContentfulRichTextBlockRoutes":
      return item.page.routes.map((route) => (
        <NavigationLink
          to={`/${getSectionRoute(locale, Sections.CONTAINER_SHIPPING)}/${
            route.slug
          }`}
          label={route.name}
          type="gatsby-link"
          data-test-id="navigation-link"
        />
      ));
  }
  if (item.childMenuItem) {
    return item.childMenuItem?.map((item) => {
      if (item.url) {
        return (
          <NavigationLink
            to={item.url}
            label={item.title}
            data-test-id="navigation-link"
          />
        );
      }
      if (item.page?.__typename === "ContentfulPage") {
        return (
          <NavigationLink
            to={"/" + item.page?.seoMeta?.slug}
            label={item.title || item.page?.seoMeta?.slug}
            type="gatsby-link"
            data-test-id="navigation-link"
          />
        );
      }
    });
  }
  return null;
};
