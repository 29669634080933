import React, { FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import { backgroundGrey, darkBlue } from "site-ui/colors";
import { MenuItem } from "../types";

export interface NavigationGroupProps {
  item?: MenuItem;
  icon?: ReactNode;
  footer?: ReactNode;
  label: string;
  children?: ReactNode | ReactNode[];
}

export const NavigationGroup: FC<NavigationGroupProps> = ({
  label,
  children,
  icon,
  footer,
}) => {
  return (
    <Wrapper>
      <Heading>
        <IconContainer>{icon}</IconContainer>
        <Label>{label}</Label>
      </Heading>
      <ItemsWrapper>
        {React.Children.map(children, (child) => (
          <li>{child}</li>
        ))}
      </ItemsWrapper>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-items: stretch;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
`;

const ItemsWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  flex-grow: 2;
  padding: 0;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 24px 0;
  }
`;

const spacerStyles = css`
  border-bottom: 1px solid ${backgroundGrey};
  display: block;
  content: "";
`;

const Heading = styled.div`
  display: flex;
  &:before {
    margin-bottom: 16px;
    padding-top: 16px;
    ${spacerStyles}
  }
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: flex-start;
  }
`;
const IconContainer = styled.div`
  width: 42px;
  font-size: 5px;
  img {
    max-width: 24px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  width: 100%;
  color: ${darkBlue};
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 24px 0;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    &:after {
      ${spacerStyles}
      padding-bottom: 16px;
    }
  }
`;

const Footer = styled.div`
  padding: 16px 24px 16px 42px;
  margin-top: 16px;
  font-weight: bold;
  & > * {
    color: ${darkBlue};
    &:after {
      display: inline-block;
      margin-left: 8px;
      width: 20px;
      height: 20px;
      content: "";
      margin-bottom: -4px;
      transform: rotate(-90deg);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16' class='MenuItemContainer__ChevronStyled-gnSSYZ kZwhIs'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h16v16H0z'/%3E%3Cpath fill='${encodeURIComponent(
        darkBlue
      )}'  fill-rule='nonzero' d='M3.529 6.195a.666.666 0 01.88-.055l.062.055L8 9.723l3.529-3.528a.666.666 0 01.88-.055l.062.055c.24.24.26.619.056.88l-.056.063-4 4a.668.668 0 01-.88.056l-.062-.056-4-4a.668.668 0 010-.943z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
`;
