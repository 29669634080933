import React from "react";
import { FC } from "react";

export const Close: FC<{ fill: string; width: number; height: number }> = ({
  fill,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={fill || "url(#icon-gradient)"}
        fill-rule="nonzero"
        d="M6.613 5.21l.094.083L12 10.585l5.293-5.292.094-.083a1 1 0 011.32 1.497L13.415 12l5.292 5.293a1 1 0 01-1.32 1.497l-.094-.083L12 13.415l-5.293 5.292-.094.083a1 1 0 01-1.32-1.497L10.585 12 5.293 6.707a1 1 0 011.32-1.497z"
      />
      <path
        fill="currentColor"
        fill-rule="nonzero"
        d="M6.613 5.21l.094.083L12 10.585l5.293-5.292.094-.083a1 1 0 011.32 1.497L13.415 12l5.292 5.293a1 1 0 01-1.32 1.497l-.094-.083L12 13.415l-5.293 5.292-.094.083a1 1 0 01-1.32-1.497L10.585 12 5.293 6.707a1 1 0 011.32-1.497z"
      />
    </g>
  </svg>
);
