import React from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { DataProvider } from "./providers/PageContextProvider";
import SEO from "./components/SEO";
import { GlobalStyle } from "site-ui/src/components/GlobalStyle";
import Tracking from "./components/Tracking";
import { Container } from "./components/Container";
import { ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { theme } from "site-ui/src/theme";
import { SubFooter } from "./components/SubFooter";
import CssBaseline from "@material-ui/core/CssBaseline";
import { colorTheme } from "site-ui/colors";
import { LiveChatButton } from "site-ui/src/components/LiveChat";

const th = colorTheme();

export const wrapPageElement = ({ data, element, props, ...restProps }) => {
  return (
    <StyledThemeProvider
      theme={{
        ...theme,
        ...th,
        mode: "light",
      }}
    >
      <ThemeProvider theme={theme}>
        <DataProvider value={props}>
          <CssBaseline />
          <GlobalStyle />
          <SEO
            lang={props.pageContext.locale}
            {...props.pageContext.seoMeta}
            noIndex={props.pageContext.noIndex}
          />
          <LiveChatButton />
          <Container
            header={
              <Header
                shortMenu={props.pageContext.shortMenu}
                {...props.pageContext.header}
              />
            }
            content={element}
            footer={
              props.pageContext.nofooter ||
              props.pageContext.disableFooterNavigation ? null : (
                <Footer />
              )
            }
            subFooter={
              <SubFooter theme={props.pageContext.footerTheme || "dark"} />
            }
          />

          <Tracking env={process.env.GATSBY_ENV} />
        </DataProvider>
      </ThemeProvider>
    </StyledThemeProvider>
  );
};
