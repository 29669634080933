import React, { useContext } from "react";
import { DataContext } from "../../providers/PageContextProvider";
import { FooterTeaser } from "site-ui/src/components/FooterTeaser";
import { ButtonLink } from "site-ui/src/components/Button";
import { graphql, useStaticQuery } from "gatsby";
import Typography from "@material-ui/core/Typography";
import { GatsbyImage } from "gatsby-plugin-image";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { darkBlue, midBlue, lightBlue, white, textColor } from "site-ui/colors";

const bgColor = (theme) => {
  switch (theme) {
    case "midBlue":
      return midBlue;
    case "darkBlue":
      return darkBlue;
    case "lightBlue":
    default:
      return lightBlue;
  }
};

const color = (theme) => {
  switch (theme) {
    case "darkBlue":
    case "midBlue":
      return white;
    case "lightBlue":
    default:
      return textColor;
  }
};

export const FooterTeaserContainer = () => {
  const {
    pageContext: { locale, contentful_id, footerTeaserId },
  } = useContext(DataContext);

  const result = useStaticQuery(graphql`
    query FooterTeaserBlockQuery {
      allContentfulBlockFooterTeaser {
        edges {
          node {
            id
            contentful_id
            cta
            headline
            node_locale
            link {
              url
              title
            }
            subtitle
            theme
            page {
              contentful_id
            }
            features {
              title
            }
            image {
              description
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 980
                    height: 373
                    quality: 80
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    allContentfulBlockFooterTeaser: { edges: blocks },
  } = result;

  if (!blocks || blocks.length === 0) return null;

  const teaserBlock =
    blocks.find(({ node: item }) => {
      return (
        item.node_locale === locale &&
        ((item.page && item.page[0].contentful_id === contentful_id) ||
          footerTeaserId === item.contentful_id)
      );
    }) || null;

  if (!teaserBlock) return null;

  const { node: block } = teaserBlock;
  const { headline, theme, subtitle, cta, link, features, image } = block;

  return (
    <FooterTeaser
      heading={headline}
      text={<Typography variant="subtitle1">{subtitle}</Typography>}
      bgColor={bgColor(theme)}
      color={color(theme)}
      cta={
        <ButtonLink
          onClick={() => {
            trackCustomEvent({
              category: "CTA",
              action: cta,
              label: link.url,
            });
          }}
          href={link.url}
        >
          {cta}
        </ButtonLink>
      }
      features={features}
      image={
        image &&
        image.localFile && (
          <GatsbyImage
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={image.description}
            height="100%"
          />
        )
      }
    />
  );
};
