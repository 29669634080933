import React, { FC } from "react";

export const Info: FC<{ fill: string; width: number; height: number }> = ({
  fill,
  width,
  height,
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height}>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={fill || "url(#icon-gradient)"}
        fill-rule="nonzero"
        d="M19.361 7.75c1.548 2.681 1.632 5.493.252 8.318a1.002 1.002 0 01-1.798-.878c1.09-2.23 1.028-4.338-.186-6.44a6.5 6.5 0 10-2.379 8.88 1 1 0 111 1.731A8.5 8.5 0 1119.361 7.75zM12 11a1 1 0 01.993.883L13 12v3a1 1 0 01-1.993.117L11 15v-3a1 1 0 011-1zm0-3a1 1 0 110 2 1 1 0 010-2z"
      />
      <path
        fill="currentColor"
        fill-rule="nonzero"
        d="M19.361 7.75c1.548 2.681 1.632 5.493.252 8.318a1.002 1.002 0 01-1.798-.878c1.09-2.23 1.028-4.338-.186-6.44a6.5 6.5 0 10-2.379 8.88 1 1 0 111 1.731A8.5 8.5 0 1119.361 7.75zM12 11a1 1 0 01.993.883L13 12v3a1 1 0 01-1.993.117L11 15v-3a1 1 0 011-1zm0-3a1 1 0 110 2 1 1 0 010-2z"
      />
    </g>
  </svg>
);
