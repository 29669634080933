import React from "react";
import styled from "styled-components";

export const Container = ({ header, footer, subFooter, content }) => {
  return (
    <Wrapper>
      {header}
      <main>{content}</main>
      {footer}
      {subFooter}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @supports (display: grid) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
`;
