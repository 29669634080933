import React, { FC, useEffect } from "react";
import { MenuItem, NavigationProps } from "./types";
import { globalHistory } from "@reach/router/lib/history";

export enum ActionTypes {
  OPEN = "OPEN_NAVIGATION",
  CLOSE = "CLOSE_NAVIGATION",
  TOGGLE = "TOGGLE_NAVIGATION",
  OPEN_MOBILE_MENU = "OPEN_MOBILE_MENU",
  CLOSE_MOBILE_MENU = "CLOSE_MOBILE_MENU",
  TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU",
}
type State = { isOpen: boolean; isMobileMenuOpen: boolean; dropdownId: string };
type Action =
  | { type: ActionTypes.OPEN; id: string }
  | { type: ActionTypes.CLOSE }
  | { type: ActionTypes.OPEN_MOBILE_MENU }
  | { type: ActionTypes.CLOSE_MOBILE_MENU }
  | { type: ActionTypes.TOGGLE_MOBILE_MENU }
  | { type: ActionTypes.TOGGLE; id: string };
type Dispatch = (action: Action) => void;

const NavigationContext = React.createContext<
  | {
      state: State;
      dispatch: Dispatch;
      menu: MenuItem[];
      menuBarOverlay: boolean;
      mode: string;
    }
  | undefined
>(undefined);

const initialState = {
  isMobileMenuOpen: false,
  isOpen: false,
  dropdownId: "",
};

function navigationReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionTypes.OPEN:
      return { ...state, isOpen: true, dropdownId: action.id };
    case ActionTypes.CLOSE:
      return { ...state, isOpen: false };
    case ActionTypes.TOGGLE:
      if (state.isOpen && action.id === state.dropdownId) {
        return { ...state, isOpen: false, dropdownId: null };
      }
      return { ...state, isOpen: true, dropdownId: action.id };
    case ActionTypes.OPEN_MOBILE_MENU:
      return { ...state, isMobileMenuOpen: true };
    case ActionTypes.CLOSE_MOBILE_MENU:
      return { ...state, isMobileMenuOpen: false };
    case ActionTypes.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpen: !state.isMobileMenuOpen,
        isOpen: false,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const NavigationProvider: FC<NavigationProps> = ({
  children,
  menu,
  menuBarOverlay,
  mode,
}) => {
  const [state, dispatch] = React.useReducer(navigationReducer, initialState);
  const value = { state, dispatch, menu, menuBarOverlay, mode };
  useEffect(() => {
    return globalHistory.listen(({ action }: { action: any }) => {
      if (action === "PUSH") {
        dispatch({ type: ActionTypes.CLOSE });
        dispatch({ type: ActionTypes.CLOSE_MOBILE_MENU });
      }
    });
  }, [state.isOpen]);
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

function useNavigationState() {
  const context = React.useContext(NavigationContext);
  if (context === undefined) {
    throw new Error(
      "useNavigationState must be used within a NavigationProvider"
    );
  }
  return context;
}

export { NavigationProvider, useNavigationState };
