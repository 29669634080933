import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

export const Chevron = ({ fill = "#122146", ...props }) => {
  let rotation = 0;
  if (props.right) {
    rotation = -90;
  }
  if (props.left) {
    rotation = 90;
  }
  if (props.top) {
    rotation = 180;
  }
  const theme = useContext(ThemeContext);
  return (
    <svg role="button" width={16} height={16} viewBox="0 0 16 16" {...props}>
      <title>Menu toggle</title>
      <defs>
        <linearGradient id="prefix__a" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#50AFC8" />
          <stop offset="49.658%" stopColor="#266593" />
          <stop offset="99.919%" stopColor="#142145" />
          <stop offset="100%" stopColor="#142146" />
        </linearGradient>
      </defs>
      <g transform={`rotate(${rotation}, 8, 8)`} fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path
          fill={theme.mode === "dark" ? "white" : fill || "url(#prefix__a)"}
          fillRule="nonzero"
          d="M3.529 6.195a.666.666 0 01.88-.055l.062.055L8 9.723l3.529-3.528a.666.666 0 01.88-.055l.062.055c.24.24.26.619.056.88l-.056.063-4 4a.668.668 0 01-.88.056l-.062-.056-4-4a.668.668 0 010-.943z"
        />
      </g>
    </svg>
  );
};
