import React from "react";
import { Linkedin } from "./Linkedin";
import { CookieInformation } from "./CookieInformation";
import { FacebookDomainVerification } from "./FacebookDomainVerification";
import { SmartlingContext } from "./SmartlingContext";
import { BaiduTracking } from "./Baidu";
import { Hotjar } from "./Hotjar";

export {
  Linkedin,
  CookieInformation,
  FacebookDomainVerification,
  Hotjar,
  BaiduTracking,
};

export default ({ env }) => [
  <FacebookDomainVerification />,
  <CookieInformation />,
  <Linkedin />,
  <BaiduTracking />,
  env === "dev" && <SmartlingContext />,
  <Hotjar />,
];
