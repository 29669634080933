import React, { FC, ReactNode } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Container } from "@material-ui/core";
import { white, darkBlue, midBlue, lightBlue } from "site-ui/colors";
import { backgroundGrey } from "site-ui/colors";
import { textColor } from "site-ui/colors";
import styled, { css } from "styled-components";

type SectionProps = {
  padding?: number;
  heading?: ReactNode;
  align?: "left" | "center";
  variant?: "default" | "light" | "darker" | "dark" | "lightColor";
  backgroundColor?: string;
};

const getBackgroundColor = ({ variant }: { variant: string }) => {
  switch (variant) {
    case "light":
      return white;
    case "darker":
      return backgroundGrey;
    case "dark":
      return darkBlue;
    case "lightColor":
      return lightBlue;
    default:
      return "transparent";
  }
};

const getColor = ({ variant }: { variant: string }) => {
  switch (variant) {
    case "dark":
      return white;
    default:
      return textColor;
  }
};

const getLinkStyles = ({ variant }: { variant: string }) => {
  switch (variant) {
    case "lightColor":
      return css`
        color: ${darkBlue};
        border-bottom: 1px solid ${darkBlue};
      `;
    case "darker":
      return css`
        color: ${midBlue};
        &:hover {
          border-bottom: 1px solid ${midBlue};
        }
      `;
    default:
      return css`
        color: ${lightBlue};
      `;
  }
};

export const Section: FC<SectionProps & BoxProps> = ({
  children,
  padding = 4,
  heading,
  align = "left",
  variant = "default",
  backgroundColor,
  py = { xs: padding, sm: padding, md: padding * 1.9, lg: padding * 2.5 },
  px = 0,
  id,
  maxWidth,
  ...props
}) => (
  <StyledSectionWrapper
    pt={props.pt || py}
    pb={props.pb || py}
    px={px}
    variant={variant}
    textAlign={{ sm: "left", md: align }}
    {...props}
  >
    {id && <a id={id}></a>}
    <Container maxWidth={maxWidth || "lg"}>
      {heading && <Heading align={align}>{heading}</Heading>}
      {children}
    </Container>
  </StyledSectionWrapper>
);

const Heading: FC<SectionProps & BoxProps> = ({ children, align }) => (
  <Box
    mb={{ xs: 1, sm: 2, md: 4, lg: 6 }}
    textAlign={{ sm: "left", md: align }}
  >
    {children}
  </Box>
);

const StyledSectionWrapper = styled(Box)<SectionProps>`
  ${({ pt }) => css`
    padding-top: ${pt};
  `}
  background-color: ${getBackgroundColor};
  color: ${getColor};
  width: 100%;
  a {
    ${getLinkStyles};
  }
`;
