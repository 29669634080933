import React from "react";
import Helmet from "react-helmet";

export const FacebookDomainVerification = () => {
  return (
    <Helmet>
      <meta
        name="facebook-domain-verification"
        content="fdwkfrtjmbcez5t3tk8lnhquq4angl"
      />
    </Helmet>
  );
};
