import styled from "styled-components";
import { backgroundGrey, darkBlue, midBlue } from "site-ui/colors";

export const MenuItem = styled.li`
  list-style-type: none;
  color: ${darkBlue};
  margin: 0;
  padding: 16px 0;
  & > a,
  & > span {
    font-weight: bold;
    cursor: pointer;
    color: ${darkBlue};
    &:hover {
      color: ${midBlue};
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  box-sizing: border-box;
  border-bottom: 1px solid ${backgroundGrey};
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-right: 32px;
    border-bottom: none;
    display: flex;
    padding: 0;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-right: 72px;
  }
`;
