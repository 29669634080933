import merge from "lodash/merge";
export const minty: string = "#ABEBE0";
export const lightBlue: string = "#50AFC8";
export const midBlue: string = "#266593";
export const darkBlue: string = "#142146";
export const limeGreen: string = "#C8DC00";
export const grey: string = "#E3E6EB";
export const green: string = "#2DE078";
export const orange: string = "#EB7A42";
export const red: string = "#e03f3f";
export const darkBlue3: string = "#d0d3da";
export const backgroundGrey: string = "#F1F2F5";
export const lazyGrey: string = "#F5F5F5";
export const smokeGrey: string = "#666E86";
export const black: string = "#000000";
export const white: string = "#FFFFFF";

export const main: string = darkBlue;

export const textColor: string = darkBlue;

export const infoBg = "#A7D7E3";
export const warningBg = "#F4BCA0";

export interface ThemeI {
  buttons: any;
  headingColor: string;
}

export const defaultTheme = {
  mode: "light",
  buttons: {
    null: {
      bg: limeGreen,
      fg: darkBlue,
      disabledBg: backgroundGrey,
      boxShadow:
        "0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.04)",
    },
    primary: {
      bg: limeGreen,
      fg: darkBlue,
      disabledBg: backgroundGrey,
      boxShadow:
        "0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.04)",
    },
    info: {
      bg: darkBlue,
      fg: white,
      disabledBg: backgroundGrey,
      boxShadow:
        "0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.04)",
    },
    secondary: {
      bg: white,
      fg: darkBlue,
      disabledBg: backgroundGrey,
      boxShadow:
        "0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.04)",
    },
    service: {
      bg: white,
      fg: darkBlue,
      disabledBg: grey,
      disabledColor: smokeGrey,
      boxShadow:
        "0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.04)",
      disabledBoxShadow: "none",
    },
    link: {
      bg: "transparent",
      fg: midBlue,
      disabledBg: "transparent",
      boxShadow: "none",
    },
  },
  menu: {
    mainColor: darkBlue,
    mainColorHover: midBlue,
    mainColorActive: lightBlue,
    mainBarBackground: white,
    secondaryBarBackground: backgroundGrey,
    secondaryBarColor: darkBlue,
    secondaryBarColorHover: midBlue,
  },
  headingColor: darkBlue,
  textColor: darkBlue,
  bodyBackgroundColor: white,
};

export const colorTheme = (options: ThemeI) => merge(defaultTheme, options);
