import { Sections } from "../constants";

const SectionsRoutes = {
  "en-us": {
    [Sections.CONTAINER_SHIPPING]: "container-shipping",
    [Sections.FAQ]: "faq",
    [Sections.CONTACT]: "contact",
    [Sections.SERVICES]: "services",
    [Sections.KNOWLEDGE_HUB]: "knowledge-hub",
    [Sections.CUSTOMER_STORIES]: "customer-stories",
    [Sections.NEWS]: "news",
  },
  es: {
    [Sections.CONTAINER_SHIPPING]: "información-de-envío",
    [Sections.FAQ]: "preguntas-frecuentes",
    [Sections.CONTACT]: "contacto",
    [Sections.SERVICES]: "servicios",
    [Sections.KNOWLEDGE_HUB]: "centro-de-conocimientos",
    [Sections.CUSTOMER_STORIES]: "customer-stories",
    [Sections.NEWS]: "news",
  },
  "zh-hans": {
    [Sections.CONTAINER_SHIPPING]: "航运信息",
    [Sections.FAQ]: "常见问题",
    [Sections.CONTACT]: "联系方式",
    [Sections.SERVICES]: "服务",
    [Sections.KNOWLEDGE_HUB]: "资讯中心",
    [Sections.CUSTOMER_STORIES]: "customer-stories",
    [Sections.NEWS]: "news",
  },
  de: {
    [Sections.CONTAINER_SHIPPING]: "verschiffung-informationen",
    [Sections.FAQ]: "haeufig-gestellte-fragen",
    [Sections.CONTACT]: "kontakt",
    [Sections.SERVICES]: "services",
    [Sections.KNOWLEDGE_HUB]: "informationszentrum",
    [Sections.CUSTOMER_STORIES]: "customer-stories",
    [Sections.NEWS]: "news",
  },
  pt: {
    [Sections.CONTAINER_SHIPPING]: "informacoes-de-remessa",
    [Sections.FAQ]: "perguntas-frequentes",
    [Sections.CONTACT]: "contato",
    [Sections.SERVICES]: "servicos",
    [Sections.KNOWLEDGE_HUB]: "central-de-conhecimento",
    [Sections.CUSTOMER_STORIES]: "customer-stories",
    [Sections.NEWS]: "news",
  },
  "ja-jp": {
    [Sections.CONTAINER_SHIPPING]: "コンテナによる船積み",
    [Sections.FAQ]: "よくあるご質問",
    [Sections.CONTACT]: "お問合せ",
    [Sections.SERVICES]: "サービス",
    [Sections.KNOWLEDGE_HUB]: "ナレッジハブ",
    [Sections.CUSTOMER_STORIES]: "お客様の事例",
    [Sections.NEWS]: "news",
  },
  fr: {
    [Sections.CONTAINER_SHIPPING]: "transport-de-conteneurs",
    [Sections.FAQ]: "faq",
    [Sections.CONTACT]: "contact",
    [Sections.SERVICES]: "services",
    [Sections.KNOWLEDGE_HUB]: "centre-de-connaissances",
    [Sections.CUSTOMER_STORIES]: "temoignages-de-clients",
    [Sections.NEWS]: "news",
  },
  tr: {
    [Sections.CONTAINER_SHIPPING]: "konteyner-taşımacılığı",
    [Sections.FAQ]: "sss",
    [Sections.CONTACT]: "iletişim",
    [Sections.SERVICES]: "servisler",
    [Sections.KNOWLEDGE_HUB]: "bilgi-merkezi",
    [Sections.CUSTOMER_STORIES]: "musteri-hikayeleri",
    [Sections.NEWS]: "news",
  },
};

export function getSectionRoute(locale: string, section: string) {
  locale = locale.toLowerCase();
  if (!SectionsRoutes[locale]) {
    locale = "en-us";
  }

  const routes = SectionsRoutes[locale];
  return routes[section];
}
