import React, { useEffect, useState } from "react";
import {
  AlertTypes,
  Notification,
  NotificationCloseButton,
} from "site-ui/src/components/Notification";
import { useLocale } from "../../hooks/useLocale";
import axios from "axios";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Info } from "site-ui/src/icons/Info";
import { darkBlue } from "site-ui/colors";

export const NotificationContainer: FC = () => {
  const locale = useLocale();
  const [alerts, setAlerts] = useState([]);
  const [dismissedAlerts, setDismissedAlerts] = useLocalStorage(
    "twill-dismissed-alerts",
    {}
  );

  useEffect(() => {
    async function fetchAlerts() {
      const { data: nodes } = await axios.get(
        `/gatsby_assets/json/alerts.json`
      );

      nodes && setAlerts(nodes);
    }
    fetchAlerts();
  }, []);
  if (!alerts || !alerts[0]) return null;

  return alerts
    .filter(
      (node) =>
        node.node_locale == locale &&
        !Object.keys(dismissedAlerts).includes(node.contentful_id)
    )
    .map((alert) => {
      if (!alert.text) {
        return null;
      }
      let parsedTextContent = null;
      try {
        parsedTextContent = documentToReactComponents(
          JSON.parse(alert.text.json)
        );
      } catch (e) {
        return null;
      }
      return (
        <Notification
          variant={AlertTypes[alert.variant]}
          icon={
            alert.variant !== AlertTypes.FAREWELL && (
              <Info fill={darkBlue} width={24} height={24} />
            )
          }
          actions={
            alert.variant === AlertTypes.INFO && (
              <NotificationCloseButton
                onClick={() => {
                  setDismissedAlerts({
                    ...dismissedAlerts,
                    [alert.contentful_id]: true,
                  });
                }}
              />
            )
          }
        >
          {parsedTextContent}
        </Notification>
      );
    });
};
