const chineseDomain = "twill.net.cn";
const chineseLanguageTag = "zh-Hans";

function removeTrailingSlashIfNotOnHome(input) {
  if (input === "/") return input;

  if (input.endsWith("/")) {
    return input.slice(0, -1);
  }

  return input;
}

export function getCanonicalUrl(siteUrl: string, pathName: string) {
  const pathNameWithoutTrailingSlash = removeTrailingSlashIfNotOnHome(pathName);
  let canonicalUrl = `${siteUrl}${pathNameWithoutTrailingSlash}`;

  try {
    const host = window?.location.host;
    if (
      host.includes(chineseDomain) ||
      pathNameWithoutTrailingSlash.includes(chineseLanguageTag)
    ) {
      canonicalUrl = `https://www.${chineseDomain}${pathNameWithoutTrailingSlash}`;
    }
  } catch (e) {}

  return canonicalUrl;
}
