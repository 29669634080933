import { useEffect } from "react";

export const BaiduTracking = () => {
  useEffect(() => {
    // We first check if the marketing consent is given to cookieinformation
    window.addEventListener(
      "CookieInformationConsentGiven",
      function () {
        if (CookieInformation.getConsentGivenFor("cookie_cat_marketing")) {
          // If the marketing consent is given, we add the Baidu tracking script
          const baiduScript = document.createElement("script");
          baiduScript.id = "baidu-tracking";
          baiduScript.src =
            "https://hm.baidu.com/hm.js?2e6c2b4e47efea48cd73e216e7d89730";
          const firstScriptOnPage = document.getElementsByTagName("script")[0];
          firstScriptOnPage.parentNode.insertBefore(
            baiduScript,
            firstScriptOnPage
          );
        }
      },
      false
    );
    return () => {
      window.removeEventListener("CookieInformationConsentGiven", () => {});
    };
  }, []);
  return null;
};
