import React, { FC } from "react";
import { ActionTypes, useNavigationState } from "../NavigationContext";
import { NavigationDropdownContainer } from "./NavigationDropdownContainer";
import { MenuItem } from "../types";
import { MenuItem as MenuListItem } from "./MenuItem";
import { Chevron } from "../../../icons/Chevron";
import styled from "styled-components";
import { Link } from "twill-net-localization";

export const MenuItemContainer: FC<{ item: MenuItem }> = ({ item }) => {
  const {
    dispatch,
    state: { isOpen, dropdownId },
  } = useNavigationState();
  const isCurrentOpen = isOpen && dropdownId == item.id;
  if (item.childMenuItem) {
    return (
      <MenuListItem data-test-id="menu-list-item">
        <a
          onClick={() => dispatch({ type: ActionTypes.TOGGLE, id: item.id })}
          data-test-id="menu-list-link"
        >
          <span>{item.title}</span>
          <ChevronStyled top={isCurrentOpen} width={20} height={20} />
        </a>
        <NavigationDropdownContainer item={item} />
      </MenuListItem>
    );
  }
  if (item.page !== null) {
    switch (item.page?.__typename) {
      case "ContentfulPage":
        return (
          <MenuListItem>
            <Link to={"/" + item.page.seoMeta.slug}>{item.title}</Link>
          </MenuListItem>
        );
      case "ContentfulServicesPageAssembly":
        return (
          <MenuListItem>
            <span
              onClick={() =>
                dispatch({ type: ActionTypes.TOGGLE, id: item.id })
              }
            >
              <span>{item.title}</span>
              <ChevronStyled top={isCurrentOpen} width={20} height={20} />
            </span>
            <NavigationDropdownContainer item={item} />
          </MenuListItem>
        );
      default:
        return (
          <MenuListItem>
            <a
              onClick={() =>
                dispatch({ type: ActionTypes.TOGGLE, id: item.id })
              }
            >
              <span>{item.title}</span>
              <ChevronStyled top={isCurrentOpen} width={20} height={20} />
            </a>
            <NavigationDropdownContainer item={item} />
          </MenuListItem>
        );
    }
  }

  return (
    <MenuListItem>
      <span>{item.title}</span>
    </MenuListItem>
  );
};

const ChevronStyled = styled(Chevron)`
  margin-left: 4px;
  margin-top: 3px;
`;
