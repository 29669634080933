import React, { FC } from "react";
import styled from "styled-components";
import { NavigationProvider, useNavigationState } from "./NavigationContext";
import { NavigationProps } from "./types";

export const Navigation: FC<NavigationProps> = ({
  children,
  menu,
  mode = "light",
  menuBarOverlay = false,
}) => {
  return (
    <NavigationProvider menu={menu} mode={mode} menuBarOverlay={menuBarOverlay}>
      <Wrapper menuBarOverlay={menuBarOverlay}>{children}</Wrapper>
    </NavigationProvider>
  );
};
const Wrapper = styled.div<{ menuBarOverlay: boolean }>`
  width: 100%;
  z-index: 1000;
  position: relative;
  @supports (position: sticky) {
    position: sticky;
    top: 0;
  }
`;
