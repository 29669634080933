import React from "react";
import { MenuItem } from "./MenuItem";
import styled from "styled-components";
import { menuBreakpoint } from "../../../const/breakpoints";

const DropdownBase = ({ className, childMenuItem }) => {
  return (
    <ul className={className}>
      {childMenuItem.map((item) => (
        <MenuItem {...item} />
      ))}
    </ul>
  );
};

export const Dropdown = styled(DropdownBase)`
  list-style-type: none;
  font-weight: normal;
  display: none;
  li {
    border-bottom: none;
    font-weight: normal;
  }
  max-width: 1280px;
  padding: 0 24px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(0, 0, 0, 0.04);
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  z-index: 1000;
`;
