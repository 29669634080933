import React, { FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import { useNavigationState } from "../NavigationContext";
import { NavigationGroup } from "./NavigationGroup";
import { NavigationLink } from "../NavigationLink";
import { MenuItem } from "../types";
import { NavigationGroupRenderer } from "./NavigationGroupRenderer";
import { backgroundGrey, midBlue } from "site-ui/colors";
import { LocaleLink as Link, useLocale } from "gatsby-theme-twill-website";
import { Trans, useTranslation } from "twill-net-localization";
import { Sections } from "../../../../../tools/ui/constants";
import { getSectionRoute } from "../../../../../tools/ui/helpers/getSectionRoute";
import { localizeLink } from "../../../../../tools/ui/helpers/localizeLink";

export interface NavigationDropdownProps {
  item: MenuItem;
  footer?: ReactNode;
}

const getUrl = (item: MenuItem) => {
  if (item.page && item.page.page?.seoMeta) {
    return item.page?.page?.seoMeta?.slug;
  }
  if (item.page?.page && item.page.page[0] && item.page.page[0].seoMeta) {
    return `/${item.page?.page[0].seoMeta?.slug}`;
  }
  return item.url;
};

const NavigationDropdownRenderer: FC<{ item: MenuItem }> = ({ item }) => {
  const { t } = useTranslation();
  switch (item.page?.__typename) {
    case "ContentfulServicesPageAssembly":
      const { page } = item.page;
      return item.page?.categories.map((category, key) => (
        <NavigationGroup
          label={category.title}
          icon={
            category.icon && (
              <img
                src={category.icon.localFile.publicURL}
                alt={category.title}
              />
            )
          }
          footer={
            key == 0 && (
              <Link
                to={`/${page[0].seoMeta.slug}`}
                data-test-id="navigation-link"
              >
                {t("common.viewAllServices", "View all services")}
              </Link>
            )
          }
        >
          {category.service &&
            category.service
              .filter(
                (service) => !!service.seoMeta && !service.excludeFromMenu
              )
              .map((service, key) => (
                <NavigationLink
                  label={service.title}
                  type="gatsby-link"
                  data-test-id="navigation-link"
                  to={`/${page[0].seoMeta.slug}/${service.seoMeta.slug}`}
                />
              ))}
        </NavigationGroup>
      ));
    default:
      return item.childMenuItem?.map((item) => {
        const { title, icon } = item;

        return (
          <NavigationGroup
            label={title}
            footer={
              getUrl(item) && (
                <Link to={getUrl(item)} data-test-id="navigation-link">
                  <Trans
                    i18nKey="common.viewMenuItem"
                    values={{ title }}
                    defaults="View {{title}}"
                  ></Trans>
                </Link>
              )
            }
            icon={
              icon &&
              icon.localFile && (
                <img src={icon.localFile.publicURL} alt={item.title} />
              )
            }
          >
            <NavigationGroupRenderer item={item} />
          </NavigationGroup>
        );
      });
  }
};

export const NavigationDropdownContainer: FC<NavigationDropdownProps> = ({
  item,
  footer,
}) => {
  const {
    state: { isOpen, dropdownId },
  } = useNavigationState();
  const locale = useLocale();
  const { t } = useTranslation();

  return (
    <Wrapper
      isOpen={isOpen && dropdownId == item.id}
      data-test-id="navigation-dropdown"
    >
      <Inner>
        <NavigationDropdownRenderer item={item} />
      </Inner>
      <Footer>
        {footer ? (
          footer
        ) : (
          <>
            <Link
              to={localizeLink(
                locale,
                getSectionRoute(locale, Sections.CONTACT)
              )}
              data-test-id="navigation-link"
            >
              {t("common.contactCustomerSupport", "Contact customer support")}
            </Link>
            <Link
              to={localizeLink(locale, getSectionRoute(locale, Sections.FAQ))}
              data-test-id="navigation-link"
            >
              {t("common.visitHelpCenter", "Visit help center")}
            </Link>
          </>
        )}
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isOpen: boolean }>`
  display: block;
  will-change: opacity;
  transition: 0.3s opacity;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  font-weight: normal;
  overflow-y: auto;
  padding: 32px 0;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
      display: none;
      z-index: -1000;
    `};
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    ${({ isOpen }) =>
      !isOpen &&
      css`
        overflow-y: visible;
      `}
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
    top: 104px;
    padding: 0 24px;
  }
`;

const innerStyles = css`
  max-width: 1232px;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    padding: 24px 0;
  }
`;

const Inner = styled.div`
  ${innerStyles}
  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: stretch;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    grid-gap: 100px;
  }
`;

const Footer = styled.div`
  ${innerStyles}
  border-top: 1px solid ${backgroundGrey};
  font-size: 14px;
  & > * {
    margin-right: 24px;
    padding-right: 24px;
    color: ${midBlue};
    &:hover {
      text-decoration: underline;
    }
  }
  display: none;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
  }
`;
