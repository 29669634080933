import { useContext } from "react";
import DataContext from "../providers/PageContextProvider";

const useLocale = () => {
  const {
    pageContext: { locale },
  } = useContext(DataContext);
  return locale;
};

export { useLocale };
