import React from "react";
import { Menu } from "../Menu";
import { Languages } from "../../icons/Languages";
import { Box } from "@material-ui/core";
import { localizeLink } from "../../../../tools/ui/helpers/localizeLink";

const LOCALE_NAMES = {
  "en-US": "English",
  es: "Spanish",
  de: "German",
  "zh-Hans": "Chinese",
  pt: "Portuguese",
  "ja-JP": "Japanese",
  fr: "French",
  tr: "Turkish",
};

const LanguageSwitcher = ({ alternates, currentLocale }) => {
  let defaultHost = "https://www.twill.net";
  let chineseHost = "https://www.twill.net.cn";

  try {
    const host = window?.location.host;

    if (host.indexOf("twill.net") === -1) {
      const isNotDev = !host.includes("localhost");

      defaultHost = `http${isNotDev ? `s` : ``}://${host}`;
      chineseHost = `http${isNotDev ? `s` : ``}://${host}`;
    }
  } catch (e) {}

  const subMenu = alternates
    .filter((alternate) => alternate.hreflang !== currentLocale)
    .map((alternate) => {
      const host = alternate.hreflang === "zh-Hans" ? chineseHost : defaultHost;
      const localizedLink = localizeLink(alternate.hreflang, alternate.href);

      return {
        title: LOCALE_NAMES[alternate.hreflang] || alternate.hreflang,
        url: `${host}${localizedLink}`,
        "data-test-id": "language-switcher-item",
      };
    });

  return (
    <Menu
      small
      data-test-id="language-switcher-heading"
      contentfulchildren={[
        {
          title: (
            <span>
              <Languages />
              <Box ml={1} display={{ sm: "inline", md: "none" }}>
                {LOCALE_NAMES[currentLocale]}
              </Box>
            </span>
          ),
          childMenuItem: subMenu,
        },
      ]}
    ></Menu>
  );
};

export default LanguageSwitcher;
