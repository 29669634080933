import styled, { css } from "styled-components";
import { darken } from "polished";
import { Link } from "twill-net-localization";

const buttonStyles = css`
  background-color: ${(props) => props.theme.buttons[props.variant].bg};
  color: ${(props) => props.theme.buttons[props.variant].fg}!important;
  border: none;
  border-radius: 40px;
  min-height: ${(props) => (props.size === "small" ? "40px" : "56px")};

  ${(props) =>
    props.variant !== "link" &&
    css`
      padding: ${(props) => (props.size === "small" ? "0 20px" : "0 33px")};
    `}
  font-size: 16px;
  font-weight: bold;
  font-family: "Maersk Text", sans-serif;
  transform: box-shadow 0.2s;
  box-shadow: ${(props) => props.theme.buttons[props.variant].boxShadow};
  cursor: pointer;
  text-align: center;
  &:hover {
    color: ${(props) => props.theme.buttons[props.variant].fg};
    background-color: ${(props) =>
      darken(0.02, props.theme.buttons[props.variant].bg)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.buttons[props.variant].boxShadow}
      ${(props) => props.theme.buttons[props.variant].fg};
  }
  &:active {
    color: ${(props) => props.theme.buttons[props.variant].fg};
    box-shadow: ${(props) => props.theme.buttons[props.variant].boxShadow};
    background-color: ${(props) =>
      darken(0.02, props.theme.buttons[props.variant].bg)};
  }
  ${(props) =>
    props.variant == "link" &&
    css`
      &:after {
        content: "→";
        display: inline-block;
        margin-left: 8px;
        font-weight: normal;
      }
    `}
  ${(props) =>
    props.disabled &&
    `&, &:hover, &:active {background-color: ${
      props.theme.buttons[props.variant].disabledBg
    };}`}
  justify-content: center;
  ${(props) =>
    props.disabled &&
    !!props.theme.buttons[props.variant].disabledColor &&
    `&, &:hover, &:active {color: ${
      props.theme.buttons[props.variant].disabledColor
    }!important;}`}
  ${(props) =>
    props.disabled &&
    !!props.theme.buttons[props.variant].disabledBoxShadow &&
    `&, &:hover, &:active {box-shadow: ${
      props.theme.buttons[props.variant].disabledBoxShadow
    };}`}
`;

const Button = styled.button`
  ${buttonStyles}
`;

Button.defaultProps = {
  variant: "primary",
};

const ButtonLink = styled.a`
  ${buttonStyles};
  display: inline-flex;
  align-items: center;
  line-height: 1.2;
`;

ButtonLink.defaultProps = {
  variant: "primary",
};

const ButtonGatsbyLink = styled(Link)`
  ${buttonStyles};
  display: inline-flex;
  align-items: center;
  line-height: 1.2;
`;

export { Button, ButtonLink, ButtonGatsbyLink };
